<div>
    <div>
        <span>Descubre</span>
        <span>La Vera</span>
    </div>
    <div>
        <ul>
            <li>About data</li>
            <li><a href="./legal" rel=”nofollow” target="_blank" aria-label="Legal info">Legal info</a></li>
            <li><a href="./privacy" rel=”nofollow” target="_blank" aria-label="Privacy terms">Privacy terms</a></li>
            <li><a href="./cookies" rel=”nofollow” target="_blank" aria-label="Cookies utilization">Cookies utilization</a></li>
        </ul>
    </div>
    <div>
        <ul>
            <li>Our RRSS</li>
            <li><a href="https://twitter.com/descubrelavera" target="_blank" aria-label="Twitter - descubrelavera.com">Twitter</a></li>
            <li><a href="https://www.instagram.com/descubre_lavera/" target="_blank" aria-label="Instagram - descubrelavera.com">Instagram</a></li>
            <li><a href="https://www.tumblr.com/descubre-la-vera" target="_blank" aria-label="Tumblr - descubrelavera.com">Tumblr</a></li>
            <li><a href="https://www.facebook.com/profile.php?id=100094883408710" target="_blank" aria-label="Facebook - descubrelavera.com">Facebook</a></li>
        </ul>
    </div>
</div>
<div>
    <span><span>©</span>Copyright 2023 · Built with <span>♡</span></span>
</div>
